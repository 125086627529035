.card {
	box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
}

.card-body {
    padding: 3rem;
}

.card-subtext {
	color: #999;
	font-size: 1.6rem;
	margin-top: -15px;
}

.importance {
	color: #bbb;
    font-size: 1.5rem;
    margin-top: 15px;
}

#slider {
	position: relative;
	padding: 0px;
	margin: 0px;
	list-style-type: none;
	transition: height 0.3s ease-in-out;

	li.slide {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		//height: 100%;
		opacity: 0;
		z-index: 1;
		box-sizing: border-box;

		.but {
			max-width: none;
		}
	}

	li.slide.show {
		opacity: 1;
		z-index: 2;
  	}
}

#slider-nav {
	margin-top: 40px;

	.but {
		color: #ccc;
		//width: 120px !important;
		max-width: 200px !important;
		min-width: 100px !important;
		background-color: transparent;
		border: 1px #ccc solid;
		font-size: 2.2rem;
		line-height: 2.2rem;
		padding: 10px 0 12px 0;
	}

	.but:hover {
		color: darken(#ccc, 10%);
		border-color: darken(#ccc, 10%);
	}

	.but.disabled {
		color: #f2f2f2;
		border-color: #f2f2f2;
	}

	.slide-count {
		color: #ccc;
		line-height: 4rem;
		font-size: 2.2rem;
	}
}

.slide {
  animation-duration: 0.5s;
  // animation-delay: 2s;
  // animation-iteration-count: infinite;
}

.list-group-horizontal {
	padding: 0;
	margin: 0 0 0 2px;
}

.list-group-horizontal .list-group-item {
	display: inline-block;
	width: 20%;
}

.list-group-horizontal .list-group-item.height-item {
	width: 33%;

	img {
		height: 100%;
	}
}

.list-group-horizontal .list-group-item.active {
	background-color: $green;
    border-color: $green;
}

.list-group-horizontal .list-group-item {
	margin-bottom: 0;
	margin-left:-4px;
	margin-right: 0;
 	border-right-width: 0;
}

.list-group-horizontal .list-group-item:first-child {
	border-top-right-radius:0;
	border-bottom-left-radius:2.5px;
}

.list-group-horizontal .list-group-item:last-child {
	border-top-right-radius:2.5px;
	border-bottom-left-radius:0;
	border-right-width: 1px;
}