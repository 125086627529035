html {
    font-size: 62.5%; /* Sets up the Base 10 for the font-size mixin */
}

body {
    @include font-smoothing();
    @include font-size(18, 24);
    font-family: $body-font-family;
	font-weight: $body-font-weight;
    &.redesign {
        font-family: $body-font-family-redesign;
        font-weight: $body-font-weight-redesign;
    }
	font-style: normal !important;
    color: $black;
    text-align: center;
}

// sticky footer
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1 0 auto;
    position: relative;
}
// end sticky footer

p {
    font-family: $body-font-family;
	font-weight: $body-font-weight;
	font-style: normal !important;
    @include font-size(18, 24);
    color: $black;
    margin: 0 0 15px 0;
    padding: 0;

    a,
    strong {
        @include font-medium();
    }

    a:hover {
        color: $green;
    }

    &.small {
        @include font-size(14, 20);
    }

    &.note {
        @include font-medium();
        @include font-size(16, 22);
        color: $green; //#00529B;
    }

    &:last-child {
        margin: 0;
    }
}

.opp-desc-body li {
    font-family: $body-font-family;
	font-weight: $body-font-weight;
	font-style: normal !important;
    margin: 0 0 10px 0;

    font-size: 2.2rem;
    color: $black;

    a,
    strong {
        @include font-medium();
    }

    a:hover {
        color: $green;
    }
}

h1 {
    @include font-medium();
    @include font-size(60, 60);
    letter-spacing: -0.5px;
    color: $black;
}

h2,
h3 {
    @include font-medium();
    @include font-size(22, 26);
    margin-bottom: 15px;
    color: $black;
}

h3 {
    color: $green;
}

h4 {
    @include font-semibold();
    @include font-size(16, 20);
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $green;
}

a,
input,
button,
select,
textarea {
    outline: none !important;
    box-shadow: none !important;
}

a {
    color: $green-alt;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    img {
        backface-visibility: hidden;
    }
}

.but {
    position: relative;
    display: inline-block;
    background-color: $green;
    color: white;
    @include font-size(26, 26);
    @include font-medium();
    padding: 18px 0px 20px 0px;
    border-radius: 3px;
    transition: background-color 0.15s ease-in-out;
    width: 100%;
    min-width: 200px;
    max-width: 500px;
    text-align: center;

    &:hover,
    &:focus {
        color: white;
        background-color: darken($green, 10%);

        svg path {
            fill: darken($green, 10%);
        }
    }

    &.disabled {
        background-color: #ddd;
        color: #aaa;
        pointer-events: none;
    }

    &.fb {
        background-color: #3b5998;
        @include font-size(21, 21);
        padding: 18px 0px 18px 0px;
        max-width: 400px;

        img {
            vertical-align: text-top;
            display: inline-block;
            width: auto;
            height: 30px;
            margin-right: 5px;
        }

        &:hover,
        &:focus {
            background-color: darken(#3b5998, 10%);
        }
    }
}

.redesign {
    h4 {
        color: inherit;
    }
    .report-button{
        max-width: 33rem;
    }
    .but {
        display: inline-block;
        appearance: normal;
        border: none;
        box-shadow: none;
        background-color: $black-redesign;
        border: 2px solid $black-redesign;
        padding: 7px 16px 8px 16px;
        border-radius: 9999px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        letter-spacing: 0.7em;
        @include font-bold(true);
        @include font-size(13, 16, 1.3);
        transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, color 0.25s ease-in-out, transform 0.25s ease-in-out;
        min-width: inherit;
        width: inherit;

        svg g {
            fill: white;
            transition: fill 0.25s ease-in-out;
        }

        &:hover {
            background-color: white;
            border-color: $black-redesign;
            color: $black-redesign;

            svg g {
                fill: $black-redesign;
            }
        }

        &:active {
            background-color: $black-redesign;
            color: white;
        }

        @include mq($from: md) {
            padding: 10px 20px;
            @include font-size(13, 15, 1.3);
        }

        &.white {
            background-color: white;
            border-color: $black-redesign;
            color: $black-redesign;

            svg g {
                fill: $black-redesign;
            }

            &:hover {
                background-color: $black-redesign;
                border-color: white;
                color: white;

                svg g {
                    fill: white;
                }
            }

            &:active {
                background-color: white;
                color: $black-redesign;
            }
        }

        &.red {
            background-color: $red-redesign;

            &:hover {
                background-color: desaturate(darken($red-redesign, 10%), 12%);
                color: white;
            }
        }

        &.large {
            padding: 10px 26px;
            border-radius: 26px;
            @include font-size(14, 18, 1.3);
        }

        &.xlarge {
            padding: 16px 30px 18px 30px;
            border-radius: 9999px;
            @include font-size(18, 22, 1.3);
        }

        &.with-play {
            position: relative;
            padding-left: 50px;

            .play {
                position: absolute;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: white;
                left: 3px;
                top: 50%;
                transform: translateY(-50%);
                transition: background-color 0.15s ease-in-out;

                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    margin-left: 1px;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-left: 9px solid $black-redesign;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: border-left-color 0.15s ease-in-out;
                }
            }

            &:hover {
                .play {
                    background-color: $red-redesign;

                    &:before {
                        border-left-color: white;
                    }
                }
            }
        }

        &.hover-scale {
            &:hover {
                transform: scale(1.1);
            }
        }
    }

}

textarea {
    resize: none;
}

header {

    &.header-redesign {
        display: none;
    }

    &.header-original {
        position: relative;
        z-index: 999;

        .logo {
            position: absolute;
            top: 26px;
            left: 50%;
            transform: translateX(-50%);
            width: 137px;
            height: 22px;
            z-index: 999;
        }
    }
}

section {
    position: relative;
}

.max-width {
    max-width: 1140px;
}

.fade-in {
    opacity: 0;
}

.fade-in-down {
    position: relative;
    opacity: 0;
    top: -30px;
}

.bottom {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    //padding: 0px 20px 20px 20px;
}

.overlay {
    background-color: $black;
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
}

.bg-image {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover !important;
}

.bg-map {
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   z-index: 1;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;

    width: 100vw;
    height: 100vh;

    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;

    margin-top: -50vh;
    margin-bottom: -50vh;
    margin-left: -50vw;
    margin-right: -50vw;

    position: relative;
    z-index: 1;
}

.parallax-mirror img {
    left: 0;
}

#intro {
    height: 100vh;
    //min-height: 500px !important;
    background: url('../img/oregon-map.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .content {
        position: relative;
        z-index: 30;
        height: 100%;
        text-align: center;
        padding-top: 70px;
        max-width: 500px;
        margin: 0 auto;

        img {
            width: 127px;
            height: 42px;
        }

        h1,
        h2,
        p {
            color: white;

            &.small {
                color: $light-gray;
            }
        }
    }

    .bg-image {
        background: url('../img/home-bg.jpg') center center no-repeat;
    }
}

#report {
    .intro {
        min-height: 0 !important;
    }
}

#partner {
    background-color: $tint;
    text-align: center;

    p.partner-intro {
        text-transform: uppercase;
        @include font-size(13, 13);
        letter-spacing: 1.5px;
        color: $gray;
    }

    img.partner-img {
        height: 140px;
        width: auto;
    }

    .testimonial p {
        color: #666666;
    }
}

#body {
    background-color: white;

    .content {
        height: 100%;
        text-align: center;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;

        p.small {
            color: $light-gray;
        }
    }
}

#why {
    background-color: $tint;

    .content {
        height: 100%;
        text-align: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
}

#map-intro {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;

    .bg-image {
        background: url('../img/map.jpg') center center no-repeat;
    }
}

[data-page=map] {
    #map {
        background-color: $black;
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   bottom: 0;
        //   left: 0;
        //   overflow: hidden;

        width: 100vw;
        height: 100vh;

        position: relative;
        overflow: hidden;
    }
}

#bottom {
    margin-bottom: 20px;
}

#map-intro, #help {
	.content {
		position: relative;
		z-index: 30;
		height: 100%;
		text-align: center;
		padding-top: 90px;
		max-width: 500px;
		margin: 0 auto;

		img {
			width: 127px;
			height: 42px;
		}

		h1, h2, p {
			color: white;

			&.small {
				color: $light-gray;
			}
		}
	}

	.instruction {
		text-align: center;
		padding: 0 10px;

		.svg {
			width: 100%;
			height: auto;
			margin-bottom: 10px;
		}

		p {
			@include font-size(18, 20);
			@include font-medium();
		}
	}
}

#help {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	background-color: rgba(23,23,23,0.8);
	display: none;

	.content {
		position: absolute;
	    left: 50%;
	    top: 50%;
	    transform: translate(-50%, -50%);
	    height: auto;
	    width: 100%;
	    padding: 0;
	}
}

#help, #property-info {
	.close-but {
		position: absolute;
		top: 20px;
		right: 20px;
		height: 32px;
		display: block;
		padding: 10px 13px;
		border-radius: 3px;
		z-index: 5;
		background-color: rgba(23,23,23,0.6);
		color: white;
		text-transform: uppercase;
		@include font-size(13, 13);
		@include font-medium();
		letter-spacing: 1px;
		transition: background-color 0.15s ease-in-out;

		svg {
			width: 10px;
			fill: white;
			vertical-align: bottom;
			margin-right: 2px;
		}

		&:hover {
			background-color: $green;
			//color: $black;

			svg {
				//fill: $black;
			}
		}
	}
}

[data-page=map-google] {

    #ggmap {
        background-color: $black;

        width: 100vw;
        height: 100vh;

        position: relative;
        overflow: hidden;
        .temp {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 200px;
            background: transparent;
            z-index: 25;
        }

        #map-intro, #help {
	.content {
		position: relative;
		z-index: 30;
		height: 100%;
		text-align: center;
		padding-top: 90px;
		max-width: 500px;
		margin: 0 auto;

		img {
			width: 127px;
			height: 42px;
		}

		h1, h2, p {
			color: white;

			&.small {
				color: $light-gray;
			}
		}
	}

	.instruction {
		text-align: center;
		padding: 0 10px;

		.svg {
			width: 100%;
			height: auto;
			margin-bottom: 10px;
		}

		p {
			@include font-size(18, 20);
			@include font-medium();
		}
	}
}

        /*
        #map-tools {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: none;
        */

            .zoom-controls {
                position: absolute;
                top: 25px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 5;

                a {
                    img {
                        display: inline-block;
                        margin: 0 2px;
                        opacity: 1;
                        transition: opacity 0.15s ease-in-out;
                    }

                    &:hover, &:active {
                        img {
                            opacity: 0.8;
                        }
                    }
                }
            }

            .back, .help {
                display: block;
                padding: 10px 13px;
                border-radius: 3px;
                z-index: 5;
                background-color: rgba(23,23,23,0.6);
                color: white;
                text-transform: uppercase;
                @include font-size(13, 13);
                @include font-medium();
                letter-spacing: 1px;
                position: absolute;
                transition: background-color 0.15s ease-in-out;

                svg {

                    fill: white;
                    vertical-align: bottom;
                }

                &:hover {
                    background-color: $green;
                    //color: $black;

                    svg {
                        //fill: $black;
                    }
                }
            }

            .back {
                top: 30px;
                left: 20px;
                height: 32px;
                padding-left: 11px;

                svg {
                    width: 6px;
                    margin-right: 3px;
                }
            }

            .help {
                top: 30px;
                right: 20px;
                height: 32px;

                svg {
                    width: 13px;
                    margin-right: 2px;
                }
            }

            // COLINN:
            // The following is used to render additional
            // map data sources in the top-right corner of
            // the view
            .citation {
                top: 0px;
                right: 0px;

                display: block;
                padding: 4px 6px;
                border-radius: 0px;
                z-index: 5;
                background-color: rgba(23,23,23,0.7);
                color: rgba(200,200,200,1);
                @include font-size(10, 10);
                @include font-medium();
                letter-spacing: 0.25px;
                position: absolute;
            }

            .search {
                position: absolute;
                z-index: 5;
                bottom: 20px;
                width: 100%;
                max-width: 500px;
                left: 50%;
                transform: translateX(-50%);;

                .input-wrap {
                    margin: 0 20px;
                    position: relative;

                    input {
                        appearance: none;
                        background-color: white;
                        border: none;
                        border-radius: 3px;
                        padding: 10px 43px 10px 14px;
                        @include font-regular();
                        @include font-size(16, 16);
                        color: $gray;
                        display: block;
                        width: 100%;
                    }

                    button {
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        position: absolute;
                        right: 12px;
                        top: 54%;
                        transform: translateY(-50%);
                        cursor: pointer;

                        svg {
                            width: 23px;
                            height: auto;
                        }
                    }
                }
            }
        /*}*/

        .map-tools {
            display: none;
        }

        .bg-image {
            background: url("../img/map.jpg") center center no-repeat;
        }
    }
}

#property-info {
    .close-but {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 32px;
        display: block;
        padding: 10px 13px;
        border-radius: 3px;
        z-index: 5;
        background-color: rgba(23, 23, 23, 0.6);
        color: white;
        text-transform: uppercase;
        @include font-size(13, 13);
        @include font-medium();
        letter-spacing: 1px;
        transition: background-color 0.15s ease-in-out;

        svg {
            width: 10px;
            fill: white;
            vertical-align: bottom;
            margin-right: 2px;
        }

        &:hover {
            background-color: $green;
            //color: $black;

            svg {
                //fill: $black;
            }
        }
    }
}

#property-info {
    position: absolute;
    left: 0;
    bottom: -800px;
    width: 100%;
    z-index: 998;
    background-color: rgba(23, 23, 23, 0.8);
    padding: 20px;
    transition: bottom 0.3s ease-in-out;

    &.active {
        bottom: 0;
    }

    h2,
    p {
        color: white;
    }

    h2 {
        margin-bottom: 8px;
    }

    p.area {
        color: $light-gray;
        @include font-size(16, 16);
    }

    p.count {
        color: $light-gray;
        margin-top: -12px;
        font-size: 1em;
    }

    .close-but {
        position: relative;
        display: inline-block;
        top: auto;
        left: auto;
        right: auto;
        height: 34px;
        margin-bottom: 15px;
        background: transparent;
        border: 1px solid $gray;
        transition: border-color 0.2s ease-in-out;

        &:hover {
            background-color: transparent;
            border-color: white;
        }
    }
}

#request-spinner {
    margin: 120px 0 100px;
    text-align: center;
}

.icon-counting-trees {
    position: relative;
    width: 90px;
    height: 100px;
    margin: 0 auto 10px auto;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img.arrows {
        margin-left: -45.5px;
        margin-top: -39px;
        animation-name: spin;
        animation-duration: 800ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

#counting-trees {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 300px;

    p {
        @include font-size(20, 24);
        color: $green;
        @include font-medium();
    }

    .forest-size {
        clear: both;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;

        > div {
            width: 33.33%;
            float: left;

            a {
                img {
                    width: 80px;
                    height: auto;
                    opacity: 1;
                    transition: opacity 0.15s ease-in-out;
                }

                &:hover img {
                    opacity: 0.6;
                }

                &:active img {
                    opacity: 0.6;
                }
            }
        }
    }

    input[type='submit'] {
        border: none;
        background-color: $green;
        color: white;
        @include font-size(21, 21);
        @include font-medium();
        padding: 10px 25px 12px 25px;
        border-radius: 3px;
        transition: background-color 0.15s ease-in-out;
        cursor: pointer;

        &:hover,
        &:focus {
            color: white;
            background-color: darken($green, 10%);
        }
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#summary,
#request-report,
#darn {
    background-color: $tint;

    .content {
        text-align: center;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;

        p.small {
            color: $light-gray;
        }

        h1 {
            color: $black;
            @include font-size(26, 33);
        }

        .but {
            max-width: 492px;
        }

        .form-wrapper {
            //margin-left: auto;
            //margin-right: auto;

            form {
                > div {
                    margin-bottom: 6px;
                }

                label {
                    display: block;
                    text-align: left;
                }

                input {
                    font-family: helvetica, arial;
                    appearance: none;
                    width: 100%;
                    background-color: $tint;
                    //border: 1px solid $light-gray;
                    border: 1px solid $tint;
                    border-radius: 3px;
                    padding: 12px 14px;
                    @include font-size(17, 17);
                    color: $black;
                    margin-bottom: 8px;

                    &::placeholder {
                        color: #999;
                    }
                }
            }
        }
    }

    a.down {
        display: block;
        position: absolute;
        z-index: 10;
        left: 50%;
        transform: translate(-50%, 50%);
        bottom: 0;
    }

    .chart-help {
        color: #999;
        margin: 25px 0 20px;
        font-size: 2rem;
    }
}

#summary,
#detailed-report {
    #table {
        .label {
            @include font-semibold;
            @include font-size(12, 15);
            text-transform: uppercase;
            color: $gray;
            letter-spacing: 1.5px;
            margin-right: 10px;

            span {
                color: $green;
            }
        }

        .bar {
            width: 100%;
            height: 40px;
            background-color: white;
            border-radius: 3px;

            .amount {
                width: 0;
                height: 100%;
                background-color: $green;
                border-radius: 3px;
            }
        }

        .per {
            @include font-semibold;
            @include font-size(14, 14);
            color: $green;
            margin-left: 10px;
        }
    }

    /*
	#citations {
		padding: 20px 0;
		margin-top: 40px;
		background-color: #eee;
		color: #888;

		.disclaimer {
			width: 75%;
			color: #888;
			margin: 0 auto;
			@include font-size(12, 18);
		}

		.source-list {
			display: none;
			@include font-size(12, 18);

			p {
				margin: 0;
				padding: 0;
			}
		}
	}
	*/

}

#summary {
    #table {
        width: 100%;
        max-width: 492px;
        margin: 0 auto;
    }
}

#request-report {
    background-color: white;
}

#submission-error-container, #existing-parcel {
    margin-top: 40px;

    #request-report-button, #existing-report-button {
        display: block;
        margin: 60px auto;
        border-radius: 9999px;
        border: 2px solid white;
        background-color: $blue-redesign;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0px 45px 0 25px;
        height: 57px;
    }
}


#darn {
    background-color: white;
}

#thank-you {
    .content {
        text-align: center;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;

        p.small {
            color: $light-gray;
        }

        h1 {
            color: $green;
            @include font-size(32, 36);
        }
    }
}

#report {
    .intro {
        min-height: 0 !important;

        h1 {
            color: $green;
            @include font-size(32, 36);
        }

        h2 {
            color: $black;
            @include font-size(23, 27);
        }

        p.pid {
            color: $gray;
        }
    }

    #detailed-report {
        background-color: white;

        h2 {
            color: $green;
            @include font-size(26, 30);
            margin-bottom: 5px;
        }

        h3 {
            color: $black;
            @include font-size(20, 24);
        }

        hr {
            margin: 15px 0 15px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}

footer.footer {
    background-color: $black;
    text-align: center;

    .logo {
        width: 84px;
        height: auto;
    }

    p {
        font-family: $body-font-family;
        font-weight: $body-font-weight;
        font-style: normal !important;
        color: white;
        @include font-size(17, 25);
        letter-spacing: 0.3;

        strong {
            @include font-medium();
        }

        a:hover {
            color: $light-gray;
        }

        &.links {
            a {
                color: $green;
                @include font-medium();

                &:hover {
                    color: darken($green, 10%);
                }
            }

            span {
                display: inline-block;
                margin: 0 10px;
                color: $gray;
            }
        }
    }

    p.copy {
        @include font-size(13, 20);
        font-family: $body-font-family;
        font-weight: $body-font-weight;
        font-style: normal !important;
        text-transform: none;
        color: $light-gray;

        a:hover {
            color: $green;
        }
    }
}
.footer-bottom {
	color: white;
	background-color: $dark-green;
	@include font-size(12, 18);
	@include font-medium();

	ul {
		list-style: none;

		li {
			display: inline-block;
			margin-left: 15px;

			a {
				color: white;

				svg g {
					fill: white;
					transition: fill 0.15s ease-in-out;
				}

			}
		}
	}
}

input.error {
    border: 1px solid $red !important;
}

.http-error {
    width: 100%;
    margin: 140px 0;

    .code-404 {
        width: 760px;
        margin: 0 auto;
    }
}

.policy {
    text-align: left;

    h1 {
        color: $green;
        text-align: center;
        font-size: 60px;
        margin-top: 30px;
    }

    h2 {
        margin: 30px 0 5px 0;
    }
}

.opp-desc-body img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 3px;
}

.attribution {
    color: #ccc;
    font-size: 12px;
    text-align: right;
    margin-bottom: 0;
    margin-top: -5px;
    padding-right: 5px;
}

.tagline {
    color: #ccc;
    font-size: 15px;
}

.tooltip-list {
    text-align: left;
    font-size: 11px;
    margin-left: -24px;
}

.tooltip-inner {
    max-width: 320px;
    padding: 16px;
}

.tooltip_header {
    @include font-semibold();
    @include font-size(16, 20);
    letter-spacing: 1px;
    color: $green;
}

.modal-title {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
}

.modal-body {
    text-align: left;
    padding: 30px;
}

.mapboxgl-ctrl-geocoder {
	display: none !important;
}


.mapboxgl-canvas {
	position: relative !important;
}

.card-body > img {
    width: 100%;

}
.card img {
    max-width: 100%;
}


// Map Search Autocomplete
.dropdown-menu {
    width: 100%;
    left: 0 !important;
    top: -75px !important;
}

#searchauto {
    margin-bottom: 10px !important;
    max-height: 300px;
    -webkit-transform: translate3d(0, 0, 0);
    border-radius : .15em;
    overflow: auto;
    overflow-y: overlay;
    -webkit-overflow-scrolling: touch;
}

#searchauto .list-group-item {
    // color: black;
    &:hover{
        background-color:#f8f8f8;
        cursor: pointer;
    }
}

.hidespin {
    display: none;
}

.input-wrapper {

    .submit-search{
        border: none;
        background-color: white;
        color: black;
        padding-left: 10px;
        cursor: pointer;
    }
    input {
        appearance: text;
        background-color: white;
        border: none;
        height: 45px;
        border-radius: 3px;
        padding: 10px 43px 10px 14px;
        font-family: $body-font-family;
        font-weight: $body-font-weight;
        font-style: normal !important;
        @include font-size(16, 16);
        color: $gray;
        display: block;
        width: 100%;
    }
    .spinner-btn {
        border: none;
        background-color: white;
        color: black;
        padding-right: 10px;
    }
}

.hide {
    display: none;
}

.feather {
    width: 24px;
    height: 24px;
    stroke: black;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.search-tooltip {
    font-size: 0.7em;
}

.btn-search-toggle svg {
    opacity: 0.4;
}

.btn-search-toggle.selected svg {
    opacity: 1.0;
}

.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.sky-bg {
	background-image: linear-gradient(0deg, #FFFFFF 26%, #EBF7FA 61%);
	width: 100%;
	height: 700px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
#report {
	h1 {
		@include font-semibold();
		@include font-size(30, 36, -0.7);
		margin-bottom: .2em;

		@include mq($from: md) {
			@include font-size(38, 46, -0.7);
		}
	}

	h2 {
		@include font-regular();
		@include font-size(20, 28, -0.7);
		color: black;

		@include mq($from: md) {
			@include font-size(24, 34, -0.7);
		}
	}

	h3 {
		@include font-semibold();
		@include font-size(22, 24, 1);
		border-bottom: .1em solid $black;
        padding-bottom: .2em;
        color: black;
		//height: 40px;
    }
    .interested-top {
        margin-top: -.5em;
    }

    .interested button a{
        color: white;
    }

	.vert-line {
		width: .1em;
		height: 100%;
		background-color: $black;
    }


	#chart, #forest-summary {
		#chart-wrapper {
			position: relative;
            @media screen and (max-width: 768px){
                width: 65%;
                margin: 0 auto;
            }
			#chart-total-acres {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				z-index: 1;

				.total {
					@include font-semibold();
					@include font-size(46, 46);
					margin-bottom: .1em;
				}
				.acres {
					@include font-bold();
					@include font-size(17, 17, 0.7);
					text-transform: uppercase;
				}
			}

			#doughnut-chart {
				position: relative;
				max-width: 100%;
				width: 100%;
				height: auto;
                z-index: 2;
			}
		}

		#chart-legend {
			.chart-legend-item {
				border-top: .1em solid $border-gray;
				padding-top: .5em;
				margin-bottom: .2em;

				.percent {
					@include font-semibold();
					@include font-size(38, 38);
					margin-bottom: .1em;
				}

				.label {
					@include font-semibold();
					@include font-size(14, 18);
				}
			}
		}
	}


	#sidebar {
		background-color: white;
		border: .2em solid $border-gray;
        //padding: 10px 40px 20px 40px;
        padding: 2em 2em 1em 2em;

		.property {
			@include font-semibold();
			@include font-size(14, 18);
			margin: 1em 0em;
			padding-bottom: .1em;
			border-bottom: .1em solid #EFEFEF;


		}
	}

	.disclaimer {
        //padding: 10px 15px 12px;
        padding: .5em 1em 1em;
        border: 1px solid #eee;
        border-radius: .2em;
        background-color: #f8f8f8;

        p {
            color: #888;
            margin-bottom: 0;
            @include font-size(12, 18);
        }

        ul {
            display: none;
            color: #777;
            @include font-size(12, 18);
            margin: 1.5em;
            font-family: Barlow Semi Condensed,Roboto Condensed,News Cycle,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
        }


    }
    .properties{
        margin-top: 2em;
        p {
            font-size: .8em;
        }
    }
    #cta-top {
        @media screen and (max-width: 1000px){
            margin: 0 auto;
        }
    }

	#cta {
        @media screen and (max-width: 414px){
            font-size: 2.2rem;
        }
		position: relative;
		background-color: #45bbe6;
		padding:  2em 2em 2em;
        text-align: center;
        min-width: 25rem;
		color: white;
		//max-width: 300px;
		@include font-semibold();
        @include font-size(18, 26);
		img {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 4em;
			height: auto;
		}

		a {
			display: block;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 50%);
			background-color: #cd6565;
			transition: background-color 0.15s ease-in-out;
			border-radius: 2em;
            //padding: 10px 20px 10px 20px;
            padding: 1em 2em 1em 2em;
			@include font-bold();
			@include font-size(13, 13);
			letter-spacing: 0.1em;
			text-transform: uppercase;
			white-space: nowrap;

			&:hover {
				background-color: desaturate(darken($red, 10%), 12%);
			}
		}
	}
	#gmap{
        font-size: .7em;
        @media screen and (max-width: 768px){
            min-width: 100%;
        }

    }

	#accordion {
        .accordion-content {
            background-color: #333;
            //padding: 40px;
            color: white;
            display: none;
            font-family: Barlow Semi Condensed,Roboto Condensed,News Cycle,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
            p a{
                color: #8CC584!important;
            }
            button a {
                color: white;
            }
            span {
                color: white!important;
            }

            .body {
                p {
                    color: white;
                    @include font-medium();
                    @include font-size(18, 28);

                    &:last-child {
                        margin: 0;
                    }
                }

                img {
                    //margin: 20px 0 30px 0;
                    margin: 1em 0 1em 0;
                    max-width: 65rem;
                }

                .cta {
                    h2 {
                        @include font-semibold();
                        @include font-size(30, 40);
                        color: white;
                    }
                }
                li {
                    margin: 1.5em;
                }
            }
        }
		.accordion-section {
            margin-bottom: .5em;
			.accordion-heading {
				background-color: $green;
				transition: background-color 0.15s ease-in-out;
                //padding: 42px 80px 42px 40px;
                padding: 2em 3.5em 2em 2em;
				position: relative;
				color: white;
				@include font-semibold();
				@include font-size(22, 30, 1);
				text-transform: uppercase;
				cursor: pointer;

				.arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
                    //right: 40px;
                    right: 1em;
					width: 1.8em;
					height: auto;
					transition: transform 0.15s ease-in-out;

					g#Oval-Copy-4 {
						transition: fill 0.15s ease-in-out;
					}

					polyline#Path-11 {
						transition: stroke 0.15s ease-in-out;
					}
				}
			}



			&.primary .accordion-heading {
				background-color: #8CC584;

				&:hover {
					background-color: #4C7047;
				}
			}

			&.secondary .accordion-heading {
				background-color: #6DA165;

				&:hover {
					background-color: #4C7047;
				}
			}

			&.tertiary .accordion-heading {
				background-color: #598A52;

				&:hover {
					background-color: #4C7047;
				}
			}

			&.active {
				.accordion-heading .arrow {
					transform: translateY(-50%) rotate(-180deg);

					g#Oval-Copy-4 {
						fill: $black;
					}

					polyline#Path-11 {
						stroke: white;
					}
				}
			}
		}
	}
}
