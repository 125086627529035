.faq-compare-perspective {
    display: flex;
    width: 100%;
    flex-direction: row;
    // flex-wrap: wrap;

    > div {
        flex: 1;
        flex-basis: 100%;
        display: inline-block;
    }

    img {
        max-width: 100%;
    }
}
