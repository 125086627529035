$body-font-family-redesign: 'Barlow', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$body-font-weight-redesign: 400;

$body-font-family: 'Barlow Semi Condensed', 'Roboto Condensed', 'News Cycle', "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
$body-font-weight: 300;

// Sass-mq breakpoints (set to match bootstrap above)
// **************************
$mq-breakpoints: (
    xs: map-get($grid-breakpoints, xs),
    sm: map-get($grid-breakpoints, sm),
    md: map-get($grid-breakpoints, md),
    lg: map-get($grid-breakpoints, lg),
    xl: map-get($grid-breakpoints, xl)
);
