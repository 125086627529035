@import 'bootstrap';
@import 'animate';
@import 'noty';
@import 'themes/metroui';
@import 'video-js';

// SLICK
@import 'slick.scss';
@import 'slick-theme.scss';
// END SLICK

@import 'aos';
@import 'mq';


// FONTAWESOME
@import 'fontawesome';
@import 'brands';
@import 'regular';
@import 'solid';
@import 'v4-shims';
// END FONTAWESOME

@import '@typeform/embed/build/css/widget.css';
