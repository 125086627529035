

//new map styles (2020)
.map-container {
    display: block;
    .but.proceed {
        .but.proceed {
            @include font-size(30, 30);
            padding: inherit;
            background-color: transparent;
            border: none;
        }
	}

    

        .mapboxgl-ctrl-geocoder {
            display: none !important;
        }
        
        .mapboxgl-canvas {
            position: relative !important;
        }
        
        .dropshadow {
            box-shadow: 0 0 15px 0 rgba(0,0,0,0.50);
        }
        
        .icon-float-right, .icon-float-left {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);		
        }
        
        .icon-float-right {
            right: 6px;
            padding-right: 2.5em;
        
        }
        .icon-float-left {
            left: 6px;
        }
        
        .controls {
            z-index: 999;
            position: fixed;
            width: 100%;
            height: 47px;
            top: 10px;
            left: 0;
            
            @include mq($from: lg) {
                top: 20px;
            }
            
            .top-left, .top-center, .top-right {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            
            .top-left {
                left: 10px;
                
                @include mq($from: lg) {
                    left: 20px;
                }
                
                .help-indicator {
                    display: none;
                    color: white;
                    background-color: $blue-redesign;
                    padding: 15px 20px;
                    border-radius: 5px;
                    position: absolute;
                    text-align: center;
                    width: 160px;
                    top: 65px;
                    left: 5px;
                    animation: indicate 0.7s infinite alternate;
                    
                    &:before {
                        content: "";
                        display: block;
                        width: 0; 
                        height: 0; 
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 12px solid $blue-redesign;
                        position: absolute;
                        top: -12px;
                        left: 42px;
                    }
                    
                    @include mq($from: lg) {
                        left: 47px;
                        
                        &:before {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    
                    h5 {
                        color: white;
                        @include font-bold(true);
                        @include font-size(14, 20);
                        margin: 0 0 5px 0;
                        letter-spacing: 0.07em;
                    }
                    
                    p {
                        color: darken($blue-redesign, 25%);
                        @include font-semibold(true);
                        @include font-size(14, 18);
                        margin: 0;
                    }
                }
                
                @keyframes indicate {
                    from {
                        transform: translateY(-8px);
                    }
                    to {
                        transform: translateY(0px);
                    }
                }
            }
    
            .top-center {
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-width: 150px;
    
                @include mq($from: sm) {
                    max-width: 300px;
                }
                
                @include mq($from: lg) {
                    max-width: 400px;
                }
            }
            
            .top-right {
                right: 10px;
                
                @include mq($from: lg) {
                    right: 20px;
                }
            }
    
            .exit, .help, .zoom {
                display: inline-block;
                background-color: white;
                border: 2px solid white;
                color: $black-redesign;
                text-align: center;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
                cursor: pointer;
                position: relative;
                
                svg {
                    path {
                        fill: $black-redesign;
                        transition: fill 0.15s ease-in-out;
                    }
                
                    circle, polyline {
                        stroke: $black-redesign;
                        transition: stroke 0.15s ease-in-out;
                    }
                }
                
                &:hover {
                    background-color: $black-redesign;
                    color: white;
                    
                    svg {
                        path {
                            fill: white;
                        }
                    
                        circle, polyline {
                            stroke: white;
                        }
                    }
                }
            }
            
            .exit, .help {
                display: inline-block;
                padding: 0;
                border-radius: 50%;
                height: 32px;
                width: 32px;
                padding: 0;
                text-align: center;
                
                @include mq($until: md) {	
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    
                    span {
                        display: none;
                    }
                }
                
                @include mq($from: md) {
                    width: auto;
                    height: auto;
                    border-radius: 9999px;
                    padding: 10px 10px 10px 32px;
                    @include font-bold(true);
                    @include font-size(13, 13);
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    
                    span {
                        display: inline;
                    }
                }
            }
            
            .help, .zoom-in {
                margin-left: 1px;
                
                @include mq($from: sm) {
                    margin-left: 5px;	
                }
            }
            
            .zoom {
                border-radius: 50%;
                height: 32px;
                width: 32px;
                //right: 20px;
                
                @include mq($from: sm) {
                    height: 37px;
                    width: 37px;
                }
                            
                .horiz, .vert {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: $black-redesign;
                    transition: background-color 0.15s ease-in-out;				
                }
                
                .horiz {
                    height: 2px;
                    width: 16px;
                }
                
                .vert {
                    height: 16px;
                    width: 2px;
                }
                
                &:hover {
                    .horiz, .vert {
                        background-color: white;
                    }
                }
            }
            
            .search-wrap {
                position: relative;
                
                input.search {
                    width: 100%;
                    background-color: $black-redesign;
                    border: 2px solid white;
                    border-radius: 9999px;
                    height: 47px;
                    padding-left: 44px;
                    color: white;
                    @include font-medium();
                    @include font-size(18, 18);
                    
                    &::placeholder {
                        color: $medium-gray;
                    }
                }
                
                .search-go {
                    cursor: pointer;
                }

                .feather {
                    stroke: white;
                }
            }
        }
        
        #bottom {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            width: 100%;
            padding: 10px 0 20px;
            z-index: 999;
            background: rgba(0,0,0,0.4);
            display: none;

            .show {
                display: block;
            }

            .property-slide {
                .info {
                    .area {
                        text-transform: capitalize;
                    }
                }
            }
            
            .total-acres, .property {
                .options {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 7px;
                    font-size: 0;
                    width: 27px;
                    
                    a {
                        display: block;
                        margin: 0 0 2px 0;
                        font-size: 0;
                        width: 100%;

                        img {
                            width: 100%;
                            height: auto;
                            opacity: 0.6;
                            transition: opacity 0.15s ease-in-out;
                        }
                        
                        svg {
                            width: 100%;
                            height: auto;
                            opacity: 0.3;
                            transition: opacity 0.15s ease-in-out;
                            
                            #crosshairs {
                                fill: $black-redesign;
                            }
                            
                            #x polygon {
                                fill: $black-redesign;
                            }
                        }
                        
                        &:hover {
                            img, svg {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            
            .total-acres {
                .options {
                    width: 22px;
                }
            }
            
            .total-acres {
                display: none;
                position: relative;
                background-color: $black-redesign;
                color: white;
                @include font-bold(true);
                @include font-size(12, 12);
                letter-spacing: 0.07em;
                border-radius: 9999px;
                padding: 9px 45px 10px 15px;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            .property-slide {
                text-align: center;
            }
                
            .property {
                padding: 12px 40px 15px 17px;
                width: 95%;
                background-color: white;
                color: white;
                margin: 3px 3px;
                position: relative;
                text-align: left;
                border-radius: 5px;
                display: inline-block;
                transform: translateX(-40px);
                opacity: 0;
                transition: opacity 0.40s ease-out, transform 0.30s ease-out;
                overflow: hidden;
                
                &.animated {
                    transform: translateX(0px);
                    opacity: 1;
                }
                
                .deselect-confirm-box, .goto-confirm-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $red-redesign;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    color: white;
                    text-align: center;
                    display: none;
                    
                    .center-wrap {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                    }
                    
                    h4 {
                        @include font-semibold(true);
                        @include font-size(16, 20);
                        margin-bottom: 8px;
                    }
                    
                    a {
                        display: inline-block;
                        background-color: white;
                        border-radius: 9999px;
                        @include font-bold(true);
                        @include font-size(12, 12, 0.7);
                        text-transform: uppercase;
                        color: $black-redesign;
                        padding: 5px 10px 7px 10px;
                        margin: 0 3px;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
                        
                        &:hover {
                            background-color: $black-redesign;
                            color: white;
                        }
                    }
                }
                
                .goto-confirm-box {
                    background-color: $green;
                }
                
                .info {
                    h4 {
                        @include font-semibold(true);
                        @include font-size(22, 28);
                        margin-bottom: 0px;
                        color: $blue-redesign;
                    }
                    
                    p {
                        color: $medium-gray;
                        @include font-semibold(true);
                        @include font-size(12, 18);
                        margin: 0;
                    }
                }

                @include mq($from: md) {
                    padding: 15px 40px 18px 20px;
                                        
                    .info {
                        h4 {
                            margin-bottom: 3px;
                        }
                    }
                }
            }
            
            .submit {
                display: inline-block;
                position: relative;
                border-radius: 9999px;
                border: 2px solid white;
                background-color: $blue-redesign;
                color: white;
                text-transform: uppercase;
                padding: 0px 45px 0 25px;
                height: 57px;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
                cursor: pointer;
                white-space: nowrap;
                
                .title {
                    @include font-bold(true);
                    @include font-size(18, 18);
                    letter-spacing: 0.07em;
                    margin-top: 16px;
                }

                svg g {
                    stroke: white;
                    transition: stroke 0.15s ease-in-out;
                }
                
                &.disabled {
                    border-color: white;
                    background-color: $medium-gray;
                    color: white;
                    
                    svg g {
                        stroke: white;
                    }
                }
                
                &:hover {
                    border-color: white;
                    background-color: $black-redesign;
                    color: white;
                    
                    svg g {
                        stroke: white;
                    }
                }
            }
            
            .slick-list {
                padding: 0 36px;
                
                @include mq($from: md) {
                    padding: 0 30px;
                }
            }
            
            
            
            .slick-arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 99;
                transition: opacity 0.15s ease-in-out;
                cursor: pointer;
                
                &.prev {
                    left: 10px;
                }
                
                &.next {
                    right: 10px;
                }
                
                &.slick-disabled {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }
        
        .click-help {
            display: none;
            z-index: 99999;
            position: fixed;
            background-color: rgba(0,0,0,0.6);
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            cursor: pointer;
            
            .close {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-weight: 500;
                opacity: 1;
                text-shadow: inherit;
                float: inherit;
                @include font-size(24, 32);
            }
            
            @include mq($until: md) {
                .close {
                    display: none;
                }
                
                .help-boxes {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    
                    .help-box {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            .help-box {
                background-color: $blue-redesign;
                padding: 15px 20px 15px 50px;
                margin-bottom: 5px;
                color: white;
                position: relative;
                max-width: 280px;
                pointer-events: none;
                border-radius: 5px;
                //margin-left: auto;
                //margin-right: auto;
                
                opacity: 0;
                transform: translateY(-15px);
                transition: transform 0.2s ease-out, opacity 0.2s ease-out;
                
                &.animated {
                    opacity: 1;
                    transform: translateY(0px);
                }

                .but {
                    min-width: auto;
                }

                h5 {
                    color: white;
                    @include font-bold(true);
                    @include font-size(14, 20);
                    margin: 0 0 3px 0;
                    letter-spacing: 0.07em;
                    
                    @include mq($from: md) {
                        @include font-size(16, 22);
                        letter-spacing: 0.07em;
                        margin-bottom: 5px;
                    }
                }
                
                p {
                    color: darken($blue-redesign, 25%);
                    @include font-semibold(true);
                    @include font-size(15, 19);
                    margin: 0;
                    
                    @include mq($from: md) {
                        @include font-size(16, 22);
                        margin-bottom: 5px;
                    }
                }

                &.error {
                    background-color: $red-redesign;
                    
                    p {
                        color: white;
                    }	
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(-50%, -50%);
                    width: 65px;
                    
                    @include mq($from: md) {
                        width: auto;
                    }
                }

                @include mq($from: md) {
                    position: absolute;
                    padding: 20px 25px 25px 50px;
                    margin-bottom: 0;

                    &.help-box-search {
                        top: 92px;
                        right: 50%;
                        
                        &:before {
                            content: "";
                            display: block;
                            width: 0; 
                            height: 0; 
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 12px solid $blue-redesign;
                            position: absolute;
                            bottom: 100%;
                            left: 75%;
                        }
                    }
                    
                    &.help-box-zoom {
                        top: 80px;
                        right: 22px;
                        
                        &:before {
                            content: "";
                            display: block;
                            width: 0; 
                            height: 0; 
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 12px solid $blue-redesign;
                            position: absolute;
                            bottom: 100%;
                            left: 75%;
                        }
                    }
                    
                    &.help-box-select {
                        top: 35%;
                        left: 10%;
                    }
                    
                    &.help-box-pan {
                        top: 45%;
                        right: 15%;
                    }
                    
                    &.help-box-submit {
                        bottom: 100px;
                        left: 50%;
                        margin-left: -140px;
                        
                        &:before {
                            content: "";
                            display: block;
                            width: 0; 
                            height: 0; 
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 12px solid $blue-redesign;
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }

            .help-box-wide {
                max-width: 300px !important;
            }
        }
        
        #first-prop-click, #none-selected, #deselect-all-confirm {
            cursor: auto;
            
            .help-box {
                padding: 50px 20px 20px 20px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%);
                text-align: center;
                max-width: 220px;
                pointer-events: all;
                
                opacity: 0;
                transition: transform 0.2s ease-out, opacity 0.2s ease-out;
                
                &.animated {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                }

                img {
                    left: 50%;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                
                .close-but {
                    display: inline-block;
                    background-color: white;
                    border: 2px solid white;
                    color: $black-redesign;
                    text-align: center;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
                    cursor: pointer;
                    position: relative;
                    
                    &:hover {
                        background-color: $black-redesign;
                        color: white;
                    }
                }
            }
        }
 
    .noty_bar {
        background-color: $red-redesign;
        padding: 18px 20px 20px 20px;
        color: white;
        @include font-medium(true);
        @include font-size(16, 22);
        border-radius: 5px;
        margin-bottom: 5px;
    }

    .searchauto {
        position: absolute;
        top: calc(100% + 5px);
        padding: 0 1.25rem;
        margin-left: 0;
    }
}