
/******************************************************** FONTS ********************************************************/

//@import url('https://fonts.googleapis.com/css?family=News+Cycle:400,700');
//@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400,500,600,700');

@mixin font-light($redesign: false) {
	$font-family: $body-font-family;
	@if $redesign {
		$font-family: $body-font-family-redesign;
	}
	font-weight: 300;
    font-style: normal;
}

@mixin font-regular($redesign: false) {
	$font-family: $body-font-family;
	@if $redesign {
		$font-family: $body-font-family-redesign;
	}
	font-weight: 400;
    font-style: normal;
}

@mixin font-medium($redesign: false) {
	$font-family: $body-font-family;
	@if $redesign {
		$font-family: $body-font-family-redesign;
	}
	font-family: $font-family;
	font-weight: 500;
    font-style: normal;
}

@mixin font-semibold($redesign: false) {
	$font-family: $body-font-family;
	@if $redesign {
		$font-family: $body-font-family-redesign;
	}
	font-family: $font-family;
	font-weight: 600;
    font-style: normal;
}

@mixin font-bold($redesign: false) {
	$font-family: $body-font-family;
	@if $redesign {
		$font-family: $body-font-family-redesign;
	}
	font-family: $font-family;
	font-weight: 700;
    font-style: normal;
}
