.wc-logo-redesign {
    display: none;
}

.logo {
    &.redesign {
        display: none;
    }
}

body.redesign {
    .logo {
        &.redesign {
            display: inline-block;
        }

        &.original {
            display: none;
        }
    }
    .wc-logo-original {
        display: none;
    }
    .wc-logo-redesign {
        display: inline-block;
    }
    &.request-report {
        padding-bottom: 0;
    }
    text-align: left;
    .sky-bg {
        background-image: linear-gradient(0deg, #FFFFFF 26%, #EBF7FA 61%);
        width: 100%;
        height: 700px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
       
    header[role=banner] {
        display: none;

        &.header-original {
            .logo {
                display: none;
            }
        }

        &.header-redesign {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 99999;
            background-color: transparent;
            border-bottom: 2px solid transparent;
            transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out;
            display: block;
            
            
            .header-height { 
                height: 70px;
                transition: height 0.35s ease-in-out;
            }
            
            .logo {
                height: 24px;
                width: auto; //144px;
                transition: height 0.35s ease-in-out, width 0.35s ease-in-out;
            }
            
            &.scrolled {
                background-color: white;
                border-bottom: 2px solid #efefef;
                
                .header-height {
                    height: 70px;
                }
                
                nav ul li a {
                    //color: white;
                }
            }
            
            .mobile-menu-open {
                cursor: pointer;
            }
            
            .mobile-menu-close {
                cursor: pointer;
                position: absolute;
                top: 22px;
                right: 19px;
            }		
        
            @include mq($until: md) {
                nav {
                    display: none;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $green;
                    z-index: 99999;
                    
                    .logo {
                        position: absolute;
                        top: 23px;
                        left: 15px;
                    }
        
                    ul {
                        text-align: center;
                        list-style: none;
                        //margin-top: 100px;
                        width: 100%;
                        position: absolute;
                        top: 48%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        
                        li {
                            display: block;
                            margin: 20px 0;
                            
                            a {
                                color: white;
                                @include font-bold();
                                @include font-size(22, 22, 1.3);
                            }
                        }
            
                        li.social {
                            display: inline-block;
                            margin: 0 10px;
                            
                            a svg {
                                display: inline-block;
                                //width: 115%;
                                //height: auto;
                                
                                g {
                                    fill: white;
                                }
                            }
                        }
            
                        li.gap {
                            margin-top: 35px;
                        }
                    }
                }
            }
                    
            @include mq($from: md) {
                .header-height { 
                    height: 90px;
                }
                
                .logo {
                    height: 29px;
                }
                
                nav {
                    display: block !important;
                    
                    .logo {
                        display: none;
                    }
                    
                    .mobile-menu-close {
                        display: none;
                    }
                    
                    ul {
                        list-style: none;
                        
                        li {
                            display: inline-block;
                            margin-left: 20px;
                            
                            &:first-child {
                                margin-left: 0;
                            }
                            
                            a {
                                @include font-bold();
                                @include font-size(14, 14, 1.3);
                                transition: color 0.15s ease-in-out;
                                
                                &:hover {
                                    color: $blue;
                                }
                            }
                        }
            
                        li.social {
                            margin-left: 15px;
                            
                            a svg g {
                                fill: $black;
                                transition: fill 0.15s ease-in-out;
                            }
                            
                            a:hover svg g {
                                fill: $blue;
                            }
                        }
            
                        li.gap {
                            margin-left: 35px;
                        }
                    }
                }
            }
        }
    }
}