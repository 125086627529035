
/******************************************************** STYLES ********************************************************/

html {
	font-size: 62.5%; /* Sets up Base 10 for the font-size mixin */
}

* {
	outline: none;
}

body {
	@include font-smoothing();
	@include font-regular();
	@include font-size(18, 18);
	color: $black;
	overflow-x: hidden;

	padding-bottom: 0; //80px;

	@include mq($from: md) {
		padding-bottom: 60px;
	}
}

body.map{
	overflow: hidden;
	height: 100vh;
}

button {
	background: none;
	border: none;
	border-radius: none;
	padding: 0;
	margin: 0;
}

a, input, button  {
    outline: none !important;
    box-shadow: none !important;
}

a {
	color: inherit;
	text-decoration: none;

	&:hover, &:focus, &:active {
		color: inherit;
		text-decoration: none;
	}

	img {
		backface-visibility: hidden;
	}
}

h1, h2, h3, h4, h5, h6, ul, ol, p {
	padding: 0;
	margin: 0;
	font-weight: normal;


	&.green {
		color: $green-redesign;
	}

	&.white {
		color: white;
	}

	&.blue {
		color: $blue-redesign;
	}
}

p {
	@include font-size(18, 28);
	margin-bottom: 15px;
	color: $medium-gray;
}

.h1 {
	@include font-medium();
	@include font-size(42, 42, -0.5);

	@include mq($from: lg) {
		@include font-size(50, 50, -0.5);
	}
}

.h2 {
	@include font-medium();
	@include font-size(26, 36, -0.5);

	@include mq($from: md) {
		@include font-size(30, 40, -0.5);
	}

	@include mq($from: xl) {
		@include font-size(36, 46, -0.5);
	}
}

.h3 {
	@include font-bold();
	@include font-size(25, 35, 1.3);
	text-transform: uppercase;
}

.h4 {
	@include font-bold();
	@include font-size(22, 32, 1.3);
	text-transform: uppercase;
}

.h5 {
	@include font-medium();
	@include font-size(26, 36);
}

.h6 {
	@include font-medium();
	@include font-size(24, 34);
}

.line {
	width: 50px;
	height: 2px;
	background-color: $black;
	margin-bottom: 20px;
	display: inline-block;

	&.green {
		background-color: $green-redesign;
	}

	&.white {
		background-color: white;
	}

	&.blue {
		background-color: $blue-redesign;
	}
}

.max-width-100 {
	width: auto;
	max-width: 100%;
}

.custom-select {
	display: inline-block;
	position: relative;
	overflow: visible;
	cursor: default;
	font-size: 20px;
	margin-bottom: 2em;
}
.custom-option {
	opacity: 0;
	position: absolute;
	top: 80%;
	left: -99999px;
	z-index: 99;
	text-align: center;
	width: 100%;
	background-color: white;
	border-radius: 20px;
	padding: 0 15px;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
	//border: 2px solid $black;
	transition: opacity 0.25s ease-in-out, top 0.25s ease-in-out, bottom 0.25s ease-in-out;
}
.select-wrap {
	display: inline-block;
	position: relative;
	overflow: visible;
	cursor: default;

	svg g {
		fill: white;
	}

	.select-options {
		opacity: 0;
		position: absolute;
		top: 80%;
		left: -99999px;
		z-index: 99;
		text-align: center;
		width: 100%;
		height: 200px;
		overflow: auto;
		background-color: white;
		border-radius: 20px;
		padding: 0 15px 8px;
		box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
		transition: opacity 0.25s ease-in-out, top 0.25s ease-in-out, bottom 0.25s ease-in-out;

		ul {
			display: block;
			list-style: none;

			li {
				display: block;

				border-bottom: 1px solid #ccc;

				a {
					display: block;
					padding: 13px 0;
					@include font-bold();
					@include font-size(14, 14, 1.3);
					transition: color 0.15s ease-in-out;
					color: $black;

					&:hover {
						color: $green-redesign;
					}
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}

	&.active {
		svg g {
			fill: $black;
		}

		.select-options {
			top: 100%;
			left: 0;
			opacity: 1;
		}

		button {
			background-color: white;
			border-color: $black;
			color: $black;
		}

		&.white button {
			background-color: $black;
			border-color: white;
			color: white;
		}
	}

	&.drop-up {
		.select-options {
			top: auto;
			bottom: 80%;
			margin-bottom: -2px;
		}

		&.active {
			.select-options {
				bottom: 100%;
			}
		}
	}

	&.drop-down {
		.select-options {
			top: 80%;
			bottom: auto;
			margin-bottom: -2px;
		}

		&.active {
			.select-options {
				bottom: 100%;
			}
		}
	}
}

.container-fluid {
	padding-left: 15px;
	padding-right: 15px;

	@include mq($from: md) {
		padding-left: 30px;
		padding-right: 30px;
	}
}

header.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999;
	background-color: transparent;
	border-bottom: 2px solid transparent;
	transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out;

	.header-height {
		height: 70px;
		transition: height 0.35s ease-in-out;
	}

	.logo {
		height: 24px;
		width: 144px;
		transition: height 0.35s ease-in-out, width 0.35s ease-in-out;
	}

	&.scrolled {
		background-color: white;
		border-bottom: 2px solid #efefef;

		.header-height {
			height: 70px;
		}

		nav ul li a {
			//color: white;
		}
	}

	.mobile-menu-open {
		cursor: pointer;
	}

	.mobile-menu-close {
		cursor: pointer;
		position: absolute;
		top: 22px;
		right: 19px;
	}

	@include mq($until: md) {
		nav {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $green-redesign;
			z-index: 99999;

			.logo {
				position: absolute;
				top: 23px;
				left: 15px;
			}

			ul {
				text-align: center;
				list-style: none;
				//margin-top: 100px;
				width: 100%;
				position: absolute;
				top: 48%;
				left: 50%;
				transform: translate(-50%, -50%);

				li {
					display: block;
					margin: 20px 0;

					a {
						color: white;
						@include font-bold();
						@include font-size(22, 22, 1.3);
					}
				}

				li.social {
					display: inline-block;
					margin: 0 10px;

					a svg {
						display: inline-block;
						//width: 115%;
						//height: auto;

						g {
							fill: white;
						}
					}
				}

				li.gap {
					margin-top: 35px;
				}
			}
		}
	}

	@include mq($from: md) {
		.header-height {
			height: 90px;
		}

		.logo {
			height: 29px;
		}

		nav {
			display: block !important;

			.logo {
				display: none;
			}

			.mobile-menu-close {
				display: none;
			}

			ul {
				list-style: none;

				li {
					display: inline-block;
					margin-left: 20px;

					&:first-child {
						margin-left: 0;
					}

					a {
						@include font-bold();
						@include font-size(14, 14, 1.3);
					}
				}

				li.social {
					margin-left: 15px;

					a svg g {
						fill: $black;
					}
				}

				li.gap {
					margin-left: 35px;
				}
			}
		}
	}
}

.slick-dots {
	// bottom: 25px;

	// @include mq($from: md) {
	// 	bottom: 30px;
	// }

	// @include mq($from: lg) {
	// 	bottom: 35px;
	// }

	li {
		width: 10px;
		height: 10px;
		margin: 0 7px;

		button {
			width: 10px;
			height: 10px;
			padding: 0;

			&:before {
				content: "" !important;
				background-color: $gray-redesign;
				width: 10px !important;
				height: 10px !important;
				border-radius: 50%;
				opacity: 1;
				transition: background-color 0.15s ease-in-out;
			}
		}

		&.slick-active button:before {
			background-color: $black;
		}
	}
}

.slick-arrow {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background-color: $black;
	transition: background-color 0.15s ease-in-out;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9999;

	&:before {
		content: "";
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 33%;
		display: block;
		width: 100%;
		height: 100%;
		transition: opacity 0.15s ease-in-out;
	}

	&.slick-prev {
		left: 5px;

		@include mq($from: md) {
			left: 20px;
			width: 32px;
			height: 32px;
		}

		@include mq($from: lg) {
			left: 40px;
			width: 42px;
			height: 42px;
		}

		&:before {
            background-image: url(../img/arrow-left.svg) !important;
			margin-left: -2px;
		}
	}

	&.slick-next {
		right: 5px;

		@include mq($from: md) {
			right: 20px;
			width: 32px;
			height: 32px;

			&:before {
				width: 32px;
				height: 32px;
			}
		}

		@include mq($from: lg) {
			right: 40px;
			width: 42px;
			height: 42px;

			&:before {
				width: 42px;
				height: 42px;
			}
		}

		&:before {
			background-image: url(../img/arrow-right.svg) !important;
			margin-left: 2px;
		}
	}

	&.slick-disabled {
		display: none !important;
	}

	&:focus {
		background-color: $black;

		&:before {
			opacity: 0.75;
		}
	}

	&:hover, &:active {
		background-color: $black;

		&:before {
			opacity: 1;
		}
	}
}

.slick-dotted.slick-slider {
	margin: 0;
}

#hero {
	background-image: linear-gradient(0deg, #FFFFFF 26%, #EBF7FA 61%);
	position: relative;
	overflow: hidden;
	height: 700px;

  .slick-dots {
		bottom: 15px;
    }

	.container-fluid, .row {
		height: 100%;
	}

	.carousel, .slick-list, .slick-track, .slide {
		position: relative;
		width: 100%;
		height: 100%;
		margin: 0;
	}

	.carousel {

        .slick-slide > div {
            height: 100%;
        }
		.slide {
			.slide-wrap {
				overflow: hidden;
				position: absolute;
				width: 100%;
				height: 100%;
				//top: 50%;
				//left: 50%;
				//transform: translate(-50%, -50%);

				h1, p {
					width: 100%;
					max-width: 600px;
					text-align: center;
					margin: 0 auto;
				}

				h2 {
					text-align: center;
					margin-bottom: 10px;

					@include mq($from: xl) {
						margin-bottom: 20px;
					}
				}

				p {
					@include font-medium();
					@include font-size(16, 26);
					color: $black;

					@include mq($from: sm) {
						@include font-size(17, 27);
					}

					@include mq($from: xl) {
						@include font-size(18, 28);
					}
				}

				h2, p {
					padding-left: 30px;
					padding-right: 30px;
				}

				img, svg {
					display: inline-block;
					position: relative;
				    //left: 50%;
				}

				.intro {
					width: 100%;
                    position: absolute;
                    margin-top: 50px;
					z-index: 2;
					left: 0;
					text-align: center;
					transform: translateY(-50%);
					top: 35%;
					padding: 0 10px 0 10px;

					@include mq($from: sm) {
						top: 36%;
						padding: 0 20px 0 20px;
					}

					@include mq($from: xl) {
						top: 35%;
						padding: 0 30px 0 30px;
					}

					h1 {
						max-width: none;
						color: $blue-redesign;
						margin-bottom: 15px;
					}

					p {
						@include font-size(18, 28);
						max-width: none;

						@include mq($from: md) {
							@include font-size(20, 30);
						}
					}
				}
			}

			.slide-content {
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
			}

			&.slide-1 {
				img, svg {
					display: block;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translate(-50%, -50%);

					&.size-mobile {
						top: 66%;
					}

					&.size-tablet {
						top: 50%;
					}

					&.size-desktop {
						width: 1027px;
						height: 656px;
				   	}
				}
			}

			/*
			&.slide-2 {
				img, svg {
					width: 627px;
					height: 426px;
				    margin-left: -313px;
				}
			}

			&.slide-3 {
				img, svg {
					width: 706px;
					height: 391px;
				    margin-left: -353px;
				}
			}

			&.slide-4 {
				img, svg {
					width: 579px;
					height: 365px;
				    margin-left: -289px;
				}
			}

			&.slide-5 {
				img, svg {
					width: 679px;
					height: 427px;
				    margin-left: -339px;
				}
			}

			&.slide-6 {
				img, svg {
					width: 749px;
					height: 387px;
				    margin-left: -374px;
				}
			}
			*/
		}


	}
}

#map {
	background-color: $green-redesign;

	#js-ammap {
		@include aspect-ratio(960, 520);

		>div:last-child {
			position: absolute !important;
			top: 0 !important;
			left: 0 !important;
		}

		g circle {
			display: none;
		}

		text {
			fill: #999999;

			tspan {
				@include font-bold();
				font-size: 13px !important;
			}
		}

		a[href='http://www.amcharts.com/javascript-maps/'] {
			display: none !important;
		}
	}
}

#testimonials {
	background-color: $light-gray-redesign;

	.photo {
		margin: 0 auto 20px auto;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		overflow: hidden;
		border: 5px solid $gray-redesign;
	}

	p {
		@include font-size(18, 28);
		text-align: center;
		color: $black;
		margin-bottom: 25px;

		@include mq($from: lg) {
			@include font-size(30, 50);
		}
	}

	p.credit {
		@include font-size(16, 16);
		margin: 0;
		color: $gray-redesign;
	}

	.slick-dots {
		bottom: -70px;
	}
}

#flip-cards {
	.flip-card {
		background-color: transparent;
		width: 100%;
		height: 480px;
		perspective: 1000px;
	}

	.flip-card-inner {
		position: relative;
		width: 100%;
		height: 100%;
		text-align: center;
		transition: transform 0.5s;
		transform-style: preserve-3d;
		cursor: pointer;
	}

	.dots {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;

		> div {
			width: 8px;
			height: 8px;
			border-radius: 4px;
			background-color: #EFEFEF;
			display: inline-block;
			margin: 0 3px;

			&.active {
				background-color: #CCCCCC;
			}
		}
	}

	.flip-card.flipped .flip-card-inner {
		transform: rotateY(180deg);
	}

	.flip-card-front, .flip-card-back {
		position: absolute;
		width: 100%;
        height: 100%;

        -webkit-perspective: 0;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0,0,0);
        visibility:visible;
		backface-visibility: hidden;

		.content {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			padding: 30px;
		}
	}

	.flip-card-front {
		background-color: white;
		border: 2px solid #EFEFEF;
		border-radius: 20px;
		transition: border-color 0.15s ease-in-out;

		img {
			margin-bottom: 25px;
		}

		h4 {
			margin-bottom: 15px;
		}

		p {
			@include font-size(20, 30);
			color: #666666;
		}

		&:hover {
			border-color: $gray-redesign;
		}
	}

	.flip-card-back {
		background-color: $black;
		border: 2px solid $black;
		border-radius: 20px;
		color: white;
		transform: rotateY(180deg);

		p {
			@include font-medium();
			@include font-size(16, 26);
			color: white;
		}

		.dots {
			> div {
				background-color: #666666;

				&.active {
					background-color: #CCCCCC;
				}
			}
		}
	}
}

#blog {
	.post {
		.post-title {
			margin-bottom: 25px;
		}

		.post-meta {
			@include font-bold();
			@include font-size(12, 12, 1);
			text-transform: uppercase;
			color: $black;

			a {
				color: $blue-redesign;
			}
		}

		.post-content {
			p {
				@include font-size(16, 26);

				a.link {
					@include font-medium();
					color: $blue-redesign;

					svg g {
						fill: $blue-redesign;
					}
				}
			}
		}
	}
}

#faq {
	.faq-search-box {
		//background-color: $blue-redesign;
		position: relative;

		input {
			border: none;
			padding: 10px 0 11px 20px;
			width: 100%;
			border: 2px solid $gray-redesign;
			border-radius: 25px;
			text-transform: uppercase;
			@include font-bold();
			@include font-size(15, 19, 1.3);

			&::placeholder {
				color: $gray-redesign;
			}
		}

		button {
			width: 36px;
			height: 36px;
			border-radius: 18px;
			background-color: $black;
			position: absolute;
			right: 4px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			transition: background-color 0.15s ease-in-out;
			border: 2px solid $black;

			svg {
				position: absolute;
				top: 48%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 17px;
				height: 17px;

				path {
					fill: white;
					transition: fill 0.15s ease-in-out;
				}
			}

			&:hover {
				background-color: white;

				svg {
					path {
						fill: $black;
					}
				}
			}
		}
	}

	.faq-cat {
		@include font-bold();
		@include font-size(18, 24, 1.3);
		text-transform: uppercase;
		color: $black;
	}


	a.contact-icon {
		img {
			transition: transform 0.15s ease-in-out;
		}

		&:hover {
			img {
				transform: scale(1.05);
			}
		}
	}

	.qa {
		.question p {
			@include font-bold();
			cursor: pointer;
			transition: color 0.15s ease-in-out;
			color: #888;
		}

		.answer {
			padding-bottom: 30px;
			display: none;

			p {
				@include font-size(16, 26);
			}
		}

		&.active {
			.question p {
				color: $red;
			}
		}
	}
}

aside.aside {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	background-color: $black;
	text-align: center;

	.row {
		height: 80px;

		@include mq($from: sm) {
			height: 60px;
		}
	}

		color: white;
		text-transform: uppercase;
		@include font-bold();
		@include font-size(13, 13, 1.3);
		margin: 0;

		@include mq($from: md) {
			@include font-size(15, 15, 1.3);
		}

		img {
			vertical-align: middle;
			display: inline-block;
			margin: 0 15px;
		}
}

footer.footer {
	.footer-top {
		background-color: $green-redesign;

		.trees {
			height: 115px;
			margin-top: -136px;
			margin-bottom: 40px;
			width: 100%;
			background: url(../img/footer-trees.svg) center center repeat-x;
		}

		.survey-link {
			@include font-size(16, 16);
			@include font-semibold();
			color: $black;
		}

		nav {
			ul {
				list-style: none;

				li {
					margin-bottom: 10px;

					a {
						@include font-size(15, 15, 0.4);
						@include font-semibold();
						color: white;
						transition: color 0.15s ease-in-out;

						&:hover {
							color: white;
						}
					}
				}
			}
		}
	}

	.footer-bottom {
		color: white;
		background-color: $dark-green;
		@include font-size(12, 18);
		@include font-medium();

		ul {
			list-style: none;

			li {
				display: inline-block;
				margin-left: 15px;

				a {
					color: white;

					svg g {
						fill: white;
						transition: fill 0.15s ease-in-out;
					}
				}
			}
		}
	}
}

.hs-featured-image-wrapper {
	.hs-featured-image {
		height: 340px;
		display: block;
		background-position: center !important;
		background-size: cover !important;
	}
}

#privacy, #tos {
	@include font-medium();

	line-height: 2.8rem;

	h2 {
		margin-top: 50px;
		margin-bottom: 12px;
	}

	p {
		margin-bottom: 4px;
	}

	li {
		margin-left: 30px;
	}
}

#info {
	a {
		color: $green-redesign;
	}
}
