
/******************************************************** COLORS ********************************************************/

$black: #171717;
$gray: #666666;
$green: #5ab74a;
$dark-green: #5C9C78;
$green-alt: #448a38;
$tint: #f4f4f4;
$medium-gray: #666666;
$light-gray: #9B9B9B;
$border-gray: #EFEFEF;

$black-redesign: #333333;
$blue-redesign: #45BBE6; //#4DB3D6;
$red-redesign: #c66; //#4DB3D6;
$green-redesign: #8CC584;
$gray-redesign: #CCCCCC;
$light-gray-redesign: #F6F6F6;


.bg-tint {
	background-color: $tint;
}