

/******************************************************** MIXINS ********************************************************/

@mixin font-smoothing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


// ie usage: @include aspect-ratio(10, 4);
@mixin aspect-ratio($width, $height) {
	position: relative;
	
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: calc(($height / $width) * 100%);
	}
	
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

// sets font size in rem and px. Root html must be set as font-size: 62.5%
// usage: @include font-size(12);
@mixin font-size($sizeValue: 1.6, $line: $sizeValue * 1.3, $letter: 0){
	font-size: ($sizeValue * 1) + px;
	line-height: ($line * 1) + px;
	letter-spacing: ($letter * 1) + px;
	font-size: calc($sizeValue / 10) + rem;
	line-height: calc($line / 10) + rem;
	letter-spacing: calc($letter / 10) + rem;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $color;
		-moz-box-shadow:inset $top $left $blur $color;
		box-shadow:inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin text-field {
 	display: inline-block;
	outline: none;
	text-decoration: none;
	font: 14px/100% Arial, Helvetica, sans-serif;
	padding: .5em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	@include rounded();
	@include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
}

@mixin button($color: $red, $text_color: $white) {
	display: inline-block;
	outline: none;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font: 14px/100% Arial, Helvetica, sans-serif;
	padding: .5em 2em .55em;
	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	@include rounded();
	@include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
	
	color: $text_color !important;
	font-weight: bold;
	border: solid 1px darken($color, 18%);
	background: $color;
	@include gradient(saturate($color, 15%), darken($color, 15%));
	
	&:hover {
		text-decoration: none;
		background: saturate($color, 10%);
		@include gradient(saturate($color, 5%), darken($color, 5%));
	}
	
	&:active {
		position: relative;
		top: 1px;
		color: saturate($color, 15%);
		@include gradient(saturate($color, 15%), lighten($color, 15%));
	}
}

@mixin rounded($radius: 0.5em) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin gradient($from, $to) {
	background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background: -moz-linear-gradient(top,  $from, $to);
	filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

@mixin appearance-none {
  -webkit-appearance: text;
  -moz-appearance: text;
}