
/******************************************************** MEDIA QUERIES ********************************************************/

// Grid breakpoints taken from bootstrap 4 variables.

//@include media-breakpoint-only(xs) { }
//@include media-breakpoint-only(sm) { }
//@include media-breakpoint-only(md) { }
//@include media-breakpoint-only(lg) { }
//@include media-breakpoint-only(xl) { }

//@include media-breakpoint-down(xs) { }
//@include media-breakpoint-down(sm) { }
//@include media-breakpoint-down(md) { }
//@include media-breakpoint-down(lg) { }

// Extra small screen / phone
@include media-breakpoint-down(xs) { 
	// #map .search {
	// 	bottom: 5%
	// }
}

// Small screen / phone
@include media-breakpoint-down(sm) {

	#map .search {
		bottom: 15%
    }
    #searchauto {
        width: 90%;
        margin: 0 auto;
    }
    .search {
        .input-wrapper {
            margin: 0 auto;
            width: 90%;
        }
    }

	#intro, #owl {
		background-attachment: scroll;
	}

}

// Medium screen / tablet
@include media-breakpoint-up(md) {

	p {
		@include font-size(22, 32);
	}
	
	h1 {
		@include font-size(90, 90);
	}
	
	h2, h3 {
		@include font-size(28, 38);
	}
	
	.but {
		@include font-size(30, 30);
		padding: 17px 0px 22px 0px;
	}
	
	header.header {
		.logo {
			top: 24px;
			width: 160px;;
			height: auto;
		}
	}
	
	#intro {
		min-height: 650px !important;
	}

	#report {
		#intro {
			min-height: 0;
		}
	}
	
	#intro .content {
		padding-top: 100px;
	}
		
	#intro, #map-intro, .help-modal {
		.content {
			max-width: 700px;
			
			svg {
				margin-bottom: 15px;
			}
			
			h1, h2, p {
				color: white;
			}
			
			h2 {
				margin-bottom: 10px;
			}
		}
	}
	
	#owl {
		height: 500px;
	}
	
	#map-intro, .help-modal {
		.instruction {
			.svg {
				width: 80%;
				margin-bottom: 15px;
			}
			
			p {
				@include font-size(20, 22);
			}
		}
	}
	
	#map {
		#map-tools {
			.search {
				bottom: 30px;
	
				.input-wrap {	
					input {
						padding: 10px 43px 10px 14px;
						@include font-size(20, 20);
					}
				}
			}
		}
	}
	
	#property-info {
		padding-bottom: 30px;
	}
	
	#counting-trees {
		max-width: 500px;
		
		.forest-size {
			> div {
				a {
					img {
						width: 100px;
					}
				}
			}
		}
	}
	
	#summary, #request-report {
		.content {
			h1 {
				@include font-size(40, 43);
			}
		}
	}
	
	#summary {
		#table {
			max-width: none;
		}
	}
	
	#report {
		#intro {
			min-height: 0 !important;
			h1 {
				@include font-size(60, 66);
			}
			
			h2 {
				@include font-size(26, 30);
			}
		}
		
		#detailed-report {	
			h2 {
				@include font-size(32, 36);
				margin-bottom: 10px;
			}
			
			h3 {
				@include font-size(26, 30);
			}
			
			hr {
				margin: 25px 0 20px 0;
			}
		}
	}

}

// Large screen / desktop
//@include media-breakpoint-up(lg) {}

@media screen and ( max-width: 767px )  {

	
	#intro {
		min-height: 317px !important;
		
		.content {
			padding-top: 70px;
			
			img {
				display: none;
			}
			
			h1 {
				@include font-size(36, 36);
				
				br {
					display: none;
				}
			}
			
			h2 {
				@include font-size(16, 24);
			}
			
			p.small {
				@include font-size(10, 14);
			}
			
			a.but {
				margin: 10px 0 !important;
			}
		}
	}

	#report {
		#intro {
			min-height: 0 !important;
		}
	}

}

// Extra large screen / wide desktop
//@include media-breakpoint-up(xl) { }

// Portrait
//@media (orientation:portrait) { }

// Landscape
// @media (orientation:landscape) { }