.redesign {
    .header {
        text-align: left;
    }
    .request-report-original {
        display: none;
	}

	.request-report-redesign {
		display: block;
		@media screen and (max-width: 414px) {
			margin-top: -3rem;
		}
	}

    .request-report-redesign {
		&.hide {
			display: none;
		}
        .header-icon {
            width: 90px;
            height: auto;

            @include mq($from: md) {
                width: 110px;
			}
			@media screen and (max-width: 414px) {
				display: none;
			}
        }

        h1 {
            @include font-semibold();
            @include font-size(30, 36, -0.7);
            margin-bottom: 5px;

            @include mq($from: md) {
                @include font-size(38, 46, -0.7);
			}
			@media screen and (max-width: 414px) {
				margin-bottom: 0rem;
				margin-top: 0rem;
			}
        }

        h2 {
            @include font-regular();
            @include font-size(20, 28, -0.7);
            color: $black-redesign;

            @include mq($from: md) {
                @include font-size(24, 34, -0.7);
			}
        }

        h3 {
            @include font-semibold();
            @include font-size(22, 24, 1);
            border-bottom: 2px solid $black-redesign;
            padding-bottom: 5px;
            color: $black;
			//height: 40px;

		}
		.report-header{

			@media screen and (max-width: 414px) {
				font-size: 2.2rem;
			}
		}

        .vert-line {
            width: 2px;
            height: 100%;
            background-color: $black-redesign;
        }

        #chart, .forest-summary {
            #chart-wrapper {
                position: relative;

                #chart-total-acres {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    z-index: 1;

                    .total {
                        @include font-semibold();
                        @include font-size(46, 46);
                        margin-bottom: 5px;
                    }
                    .acres {
                        @include font-bold();
                        @include font-size(17, 17, 0.7);
                        text-transform: uppercase;
					}
                }

                #doughnut-chart {
                    position: relative;
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    z-index: 2;
                }
            }

            .chart-legend {
                .chart-legend-item {
                    border-top: 2px solid $border-gray;
                    padding-top: 10px;
                    margin-bottom: 25px;

                    .percent {
                        @include font-semibold();
                        @include font-size(38, 38);
                        margin-bottom: 5px;
                    }

                    .label {
                        @include font-semibold();
                        @include font-size(14, 18);
					}
                }
            }
        }

        .forest-summary {
            .chart-legend {
                .chart-legend-item {
                    border-top: 2px solid $gray;
                }
            }
        }

        #sidebar {
            background-color: white;
            border: 2px solid $border-gray;
            padding: 40px 40px 20px 40px;

            .property {
                @include font-semibold();
                @include font-size(14, 18);
                margin: 10px 0;
                padding-bottom: 10px;
                border-bottom: 1px solid $border-gray;

                span {
                    @include font-regular();
                    @include font-size(11, 18);
                    color: $medium-gray;
                }
            }
        }
    }

    .survey-container {
		background-color: $black-redesign;
		padding-bottom: 10px;
		color: white;
		position: relative;

		.survey-controls {
			border-top: 2px solid $medium-gray;
			height: 60px;
			margin-left: 40px;
			margin-right: 40px;
			padding-top: 20px;
			text-align: center;
			font-size: 0; // remove space between inline block elements

			.survey-arrow {
				display: inline-block;
				vertical-align: middle;
				opacity: 0.2;
				transition: opacity 0.15s ease-in-out;
				pointer-events: none;

				&.survey-arrow-left {
					margin-right: 20px;
				}

				&.survey-arrow-right {
					margin-left: 20px;
				}

				&.active {
					opacity: 1;
					pointer-events: all;
					cursor: pointer;
				}
			}

			.survey-dots {
				display: inline-block;

				.dot {
					display: inline-block;
					background-color: transparent;
					border: 2px solid $medium-gray;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin: 0 5px;
					vertical-align: middle;

					&.visited {
						background-color: $medium-gray;
						border-color: $medium-gray;
						cursor: pointer;
					}

					&.answered {
						background-color: $green;
						border-color: $green;
						cursor: pointer;
					}

					&.active {
						background-color: white;
						border-color: white;
						cursor: pointer;
					}
				}
			}
		}

		.slide {
			text-align: center;
			padding: 20px 30px 20px 30px;

			@include mq($from: md) {
				padding: 50px 60px 50px 60px;
			}

			p {
				@include font-semibold();
				@include font-size(24, 34);
				color: white;
				margin-bottom: 35px;

				@include mq($from: md) {
					@include font-size(27, 37);
				}

				&.regular {
					@include font-regular();

					strong {
						@include font-semibold();
					}
				}

				&.note {
					@include font-semibold();
					@include font-size(16, 24);
					margin-bottom: 15px;
					@media screen and (max-width: 414px) {
						margin-bottom: .5rem;
						margin-top: -1rem;
					}
				}

				&.smallprint {
					@include font-semibold();
					@include font-size(12, 16);
					color: $gray;
					margin: 0;
				}

				@media screen and (max-width: 414px) {
					margin-bottom: 0rem;
					margin-top: 0rem;
					padding-bottom: 0rem;
					padding-top: 0rem;
					font-size: 2.15rem;
				}
			}

			form {
				input[type=text], input[type=email], input[type=tel] {
					width: 100%;
					max-width: 450px;
					background-color: white;
					border: 2px solid white;
					margin-bottom: 15px;
					@include font-semibold();
					@include font-size(15, 15);
					letter-spacing: 0.07em;
					padding: 12px 0px 13px 0px;
					border-radius: 9999px;
					text-align: center;
					color: $black-redesign;
					text-transform: uppercase;

					&::placeholder {
						color: #999999;
					}

					&.error {
						border-color: $red-redesign;
					}

					@media screen and (max-width: 414px) {
						margin-bottom: .2rem;
						margin-top: .2rem;
						padding: 1rem;
					}
				}


				input[type=tel] {
					max-width: 250px;
				}

				input[type=submit] {
					position: relative;
					border-radius: 9999px;
					border: 2px solid $blue-redesign;
					background-color: $blue-redesign;
					color: white;
					text-transform: uppercase;
					//width: 100%;
					min-width: 200px;
					height: 47px;
					transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
					cursor: pointer;
					@include font-bold();
					@include font-size(15, 15);
					letter-spacing: 0.07em;
					text-align: center;

					&:hover {
						background-color: darken($blue-redesign, 15%);
						border-color: darken($blue-redesign, 15%);
					}
					@media screen and (max-width: 414px)  {
						margin-top: -1rem;
					}
				}
			}
			.form-header{
				@media screen and (max-width: 414px) {
					margin-bottom: .5rem;
					margin-top: -.5rem;

				}
			}

			.score {
				font-size: 0; // remove space between inline block elements

				> div {
					display: inline-block;
					width: 16%;
					margin: 0 2%;
					vertical-align: top;

					.num {
						@include aspect-ratio(60, 60);
						background-color: $blue-redesign;
						border-radius: 50%;
						transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
						cursor: pointer;
						position: relative;

						.content > div {
							@include font-semibold();
							font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
							font-weight: bold;
							@include font-size(21, 21);
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}

						&:hover {
							color: $black-redesign;
							background-color: white;
						}
					}

					&.selected .num {
						color: $black-redesign;
						background-color: white;
					}

					.importance {
						margin-top: 10px;
						@include font-semibold();
						@include font-size(10, 14, 0.7);
						color: #adadad;
					}
				}
			}
		}
	}

	#typeform-container {
		width: 100%;

		iframe {
			width: 100%;
			height: 720px;
		}
	}
}
